.Footer {
    background-color: var(--color-black);
    position: fixed;
    z-index: 1;
    bottom: 0;

    height: 2.5em;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}
