.App {
    /*scroll-snap-type: y mandatory;*/
}
li{
    margin-bottom: 10px;
}

.PageContainer {
    scroll-snap-align: center;
    scroll-margin: 5em;
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

p{
    margin: 5px;   
}

.Contacts {
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.SocialsMail {
    font-size: 40px;
    padding: 50px;
    margin: 0px;
}

.Socials {
    margin: 10px;
    width: 200px;
    padding: 15px;
    font-size: 30px;
}

.progress-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 2.5em;
  height: 4px;
  background-color: var(--color-blackAlt1);
  z-index: 2;
}


