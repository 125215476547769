:root {
    --color-black: #1c1c1c;
    --color-white: #bbbbbb;
    --color-blackAlt1: #333333;
    --color-blackAlt2: #2c2c2c;
    --color-whiteAlt1: #ffffff;
    --color-whiteAlt2: #999999;
}

/*
html {
    scroll-snap-type: y mandatory;
}*/

body {
    color: var(--color-white);
    background-color: var(--color-black);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a{
    color: var(--color-white);
    text-decoration: none;
    transition: color 0.2s;
}

a:hover{
    color: var(--color-whiteAlt1);
}

.fadeIn{
    opacity: 0.985;
    animation-name: fadeInAnimation;
    animation-duration: 0.25s;
}

@keyframes fadeInAnimation {
    from{opacity: 0};
    to{opacity: 0.985};
}

