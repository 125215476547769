.Header {
    background-color: var(--color-black);
    position: fixed;
    top: 0px;

    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 1;

}

.HeaderButton {
    color: var(--color-white);
    background-color: var(--color-blackAlt2);
    width: 120px;
    height: 50px;
    margin: 8px;
    margin-bottom: 0px;
    border: 0;
    transition: all 0.2s;
    font-size: 1.2em;
    /*transition: font-size 0.2s, color 0.2s, background-color 0.2s;*/
}

.HeaderButtonCurrent {
    transition: all 0.2s;
    color: "#ffffff";
    border-bottom: 3px solid var(--color-whiteAlt2);
    border-top: 3px solid var(--color-blackAlt2);
    animation-name: HeaderButtonCuttentAnimation;
    animation-duration: 0.4s;
}
@keyframes HeaderButtonCuttentAnimation {
    0% {
    border-bottom: 3px solid var(--color-blackAlt2);
    };
    30% {
    border-bottom: 3px solid var(--color-blackAlt2);
    };
    100%{
    border-bottom: 3px solid var(--color-whiteAlt2);
    };
}


.HeaderButton:hover {
    font-size: 1.3em;
    color: var(--color-whiteAlt1);
    background-color: var(--color-blackAlt1);
}

.HeaderButton:active {
    color: var(--color-whiteAlt2);
    background-color: var(--color-blackAlt2);
}

.HeaderIndicator {
    transition: left 0.2s;
    position: absolute;
    width: 150px;
    top: 4em;
    height: 4px;
    background-color: var(--color-blackAlt1);
    z-index: 2;
}
